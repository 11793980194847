<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow+' vertical-divider' : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <vs-col
          style="padding-bottom: 10px"
          vs-offset="0"
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-w="12"
        >
          <vs-button
            v-on:click="approve()"
            color="success"
            icon-pack="feather"
            icon="icon-inbox"
          >Submit</vs-button>
        </vs-col>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
          @selectedDataFromChild="selectedRow"
        >
          <template slot="thead">
            <th width="15%">
              <vs-checkbox v-on:click="addAllChecked()" v-model="checkedAll">Check All</vs-checkbox>
            </th>
          </template>
          <template slot="tbody">
            <vs-tr
              :data="tr"
              @click="selectedRow(tr)"
              :key="indextr"
              v-for="(tr, indextr) in responseData.invoicePayments"
              v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '']"
            >
              <vs-checkbox
                  :checked="checked.includes(data[indextr].ID)"
                  v-on:click="addChecked(data[indextr].ID)"
              >Check</vs-checkbox>
              <vs-td>{{tr.BankName}} - ({{tr.BankAccountNumber}} / {{tr.BankAccountOwner}})</vs-td>
              <vs-td>{{tr.PaymentCode}}</vs-td>
              <vs-td>{{tr.PaymentMethod}}</vs-td>
              <vs-td>{{priceFormat(tr.PaymentAmount)}}</vs-td>
              <vs-td>{{priceFormat(tr.PaymentCharge)}}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '', detailHide]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            
            <div>
              <div class="vx-row margin-btm" style="width:100%;padding-left:15%">
                <img v-if="img != ''" style="max-height: 170px; max-width: auto;" :src="img" />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
  components: {
  },
  props: {
    selected: Object,
    option: Object
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "payment_code"
      },
      header: [
        {
          text: "Bank",
          sortable: false
          // width: '5%'
        },
        {
          text: "Code",
          sortable: false
          // width: '5%'
        },
        {
          text: "Method",
          sortable: false
        },
        {
          text: "Amount",
          sortable: false
        },
        {
          text: "Charge",
          sortable: false
        }
      ],
      img:'',
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      checkedAll: false,
      checked: [],
    };
  },
  computed: {},

  mounted() {
    this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].ID
  },
  methods: {
    selectedRow(tr) {
      console.log(tr);
      this.detail = true;
      this.selectedData = tr
      this.img = tr.Image
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
      } else {
        this.checked.push(val);
      }
    },
    addAllChecked() {
      // console.log(this.checkedAll)
      if (this.checkedAll == false) {
        for (var i = 0; i < this.data.length; i++) {
          if (this.checked.includes(this.data[i].ID)) {
            //
          } else {
            this.checked.push(this.data[i].ID);
            console.log(this.data[i].ID);
            console.log("this.data[i].ID");
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked.splice(this.checked.indexOf(this.data[j].ID), 1);
        }
      }
      // this.checkedAll = null;
      console.log(this.checkedAll);
    },
    closeDetail() {
     this.detail = false;
      this.selectedData = {}
    },
    approve(){
      this.$vs.loading();
      // var bodyFormData = new FormData();
      // this.checked.push(2)
      // bodyFormData.set("invoice_payment_id", this.checked);
      // bodyFormData.set('TerritoryID', this.territory);
      this.$http
        .post("api/v1/invoice/payment/customer",  {
        invoice_payment_id: this.checked
      })
        .then(resp => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          } else {
            
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.reloadData(this.params);
            this.$vs.loading.close();
          }
          this.checked.splice(0, this.checked.length)
          setTimeout(function() {
            this.$vs.loading.close();
          }, 3000);
        });
    
    },
    reloadData(params) {
      this.closeDetail()
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/list/payment/customer", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: 3
          }
        })
        .then(resp => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.invoicePayments;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    }
  }
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 60%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.colored-detail {
  border: 1px solid #7367f0;
  position: fixed;
  left: 50%;
  top: 60%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>